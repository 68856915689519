@import "./../../../SCSS/Mixins.scss";

.homepage-wrapper {
    // padding-top: 88px;

    .res-margin {
        @media(max-width:1199px) {
            margin-bottom: 40px;
        }
    }

    h2 {
        @media(max-width:1199px) {
            font-size: 28px;
            line-height: 40px;
        }
    }

    .product-section {
        margin-bottom: 100px;
        padding-top: 40PX;

        // .product-section-inner {
        //     margin-bottom: 64px;
        //     position: relative;

        //     &:after {
        //         content: "";
        //         position: absolute;
        //         bottom: 120px;
        //         width: 100%;
        //         box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
        //         height: 50%;
        //     }

        //     h1 {
        //         color: $primary;
        //         font-weight: 400;
        //         text-align: center;
        //         margin-bottom: 16px;

        //         span {
        //             font-weight: 600;
        //         }
        //     }

        //     .slider-box {
        //         width: 624px;
        //         margin: auto;
        //         margin-bottom: 50px;
        //         position: relative;
        //         padding: 0px 12px 136px;
        //         z-index: 10;

        //         &:after {
        //             content: "";
        //             position: absolute;
        //             bottom: 122px;
        //             background: linear-gradient(0, rgba(218, 183, 109, 0.7), rgba(218, 183, 109, 0) 46%);
        //             width: calc(100% + 2px);
        //             height: 100%;
        //             left: -1px;
        //             border-radius: 10px;
        //             z-index: -1;
        //         }

        //         &:before {
        //             content: "";
        //             position: absolute;
        //             bottom: 123px;
        //             background: white;
        //             width: 100%;
        //             height: 50%;
        //             left: 0px;
        //             border-radius: 10px;
        //             z-index: 0;
        //         }


        //         .react-multi-carousel-list {
        //             overflow: hidden;
        //             position: static;
        //         }

        //         .react-multi-carousel-item {
        //             padding: 12px 8px;
        //             // width: 128px;

        //             .ip_box {
        //                 padding: 24px 12px;
        //                 box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        //                 border-radius: 80px;
        //                 text-align: center;
        //                 min-height: 182px;
        //                 // width: 128px;

        //                 &:hover {
        //                     outline: 1px solid $primary;
        //                     outline-offset: -1px;
        //                 }

        //                 img {
        //                     width: 90px;
        //                     height: 90px;
        //                     margin-bottom: 2px;
        //                 }
        //             }
        //         }

        //         .react-multiple-carousel__arrow {
        //             bottom: 0;
        //             min-height: 30px;
        //             height: 40px;
        //             min-width: 30px;
        //             width: 40px;
        //             padding: 0px;
        //             z-index: 0;
        //             background: rgba(79, 79, 79, 1);

        //             &:before {
        //                 color: $white;
        //                 font-size: 12px;
        //             }

        //             &:disabled {
        //                 opacity: 0.5;
        //                 cursor: not-allowed;

        //                 &:hover {
        //                     &:before {
        //                         color: $black;
        //                     }
        //                 }
        //             }
        //         }

        //         .react-multiple-carousel__arrow--left {
        //             left: calc(50% - 50px);
        //         }

        //         .react-multiple-carousel__arrow--right {
        //             right: calc(50% - 50px);
        //         }
        //     }
        // }
        .productLinks {
            display: flex;
            flex-wrap: wrap;
        }
        .ip_box {
            padding: 12px;
            // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            text-align: center;
            min-height: 141px;
            width: calc(24% - 6px);
            cursor: pointer;

            &.commingSoon {
                position: relative;
                opacity: 0.6;
                cursor: wait;
                &:before {
                    background: url("../../../../public/images/coming-soon.svg") no-repeat center center/70%;
                    content: '';
                    position: absolute;
                    top: 12px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    outline: 1px solid rgba($primary, 0.4);
                }
            }

            &:hover {
                outline: 1px solid $primary;
                outline-offset: -1px;
                animation: clipborder 8s linear infinite;
            }

            img {
                width: 90px;
                height: 90px;
                margin-bottom: 2px;
            }

            p {
                color: rgba(51, 51, 51, 1);
            }

            @keyframes clipborder {

                0%,
                100% {
                    clip: rect(0px, 125.4px, 1px, 0px);
                }

                25% {
                    clip: rect(0px, 1px, 125.4px, 0px);
                }

                50% {
                    clip: rect(124.4px, 125.4px, 125.4px, 0px);
                }

                75% {
                    clip: rect(0px, 125.4px, 125.4px, 124.4px);
                }
            }
        }

        .sliderBox {
            min-height: 326px;
            border-radius: 24px;
            display: flex;
            align-items: center;
            padding: 24px 24px 0px;

            &.health-sb {
                background: rgba(255, 130, 134, 1) url('./../../../../public/images/banner-bg.svg') no-repeat center/100%;
            }

            &.car-sb {
                background: rgba(158, 161, 212, 1) url('./../../../../public/images/banner-bg.svg') no-repeat center/100%;
            }

            &.term-sb {
                background: rgba(251, 187, 112, 1) url('./../../../../public/images/banner-bg.svg') no-repeat center/100%;
            }

            span {
                display: inline-flex;
                border: 1px solid $white;
                gap: 12px;
                color: $white;
                border-radius: 8px;
                padding: 8px 12px;
                margin-bottom: 16px;
                font-size: 14px;
                line-height: 21px;
                align-items: center;
            }

            h4 {
                color: $white;
                margin-bottom: 24px;
            }
            .sliderImg {
                display: flex;
                flex: 1;
                align-self: flex-end;
            }
        }

        .slick-dots {
            li {
                height: 12px;
                width: 12px;
                margin: 0px 3px;

                button {
                    padding: 0;
                    background-color: rgba(224, 224, 224, 1);
                    border-radius: 50%;
                    height: 12px;
                    width: 12px;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background-color: rgba(79, 79, 79, 1);
                    }
                }
            }
        }
    }

    .redbg-section {
        margin-bottom: 100px;

        .redbox {
            background: $lightRed;
            padding: 24px;
            border-radius: 24px;
            display: flex;
            gap: 24px;
            align-items: center;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: url('./../../../../public/images/curve-bottom.svg')no-repeat 0 0/100%;
                width: 373px;
                height: 200px;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                background: url('./../../../../public/images/curve-top.svg')no-repeat 0 0/100%;
                width: 373px;
                height: 200px;
            }

            div {
                flex: 1;

                h4 {
                    span {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .about-section {
        margin-bottom: 100px;

        .aboutbg_box {
            display: flex;
            gap: 24px;
            align-items: center;

            div {
                flex: 1;

                h4 {
                    span {
                        font-weight: 500;
                    }
                }

                img {
                    max-width: 532px;
                    width: 100%;
                }
            }
        }
    }

    .why-choose {
        margin-bottom: 100px;

        .wc_box {
            @media(max-width:991px) {
                text-align: center;
            }
        }

        .wc_sec_img {
            max-width: 520px;
            width: 100%;
        }

        .wc_points {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            padding-bottom: 40px;

            li {
                box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                padding: 24px;
                width: calc(50% - 12px);
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;

                &:nth-child(2),
                &:nth-child(4) {
                    position: relative;
                    top: 40px;
                }

                img {
                    width: 90px;
                    height: 90px;
                }

                h6 {
                    margin-bottom: 12px;
                }

                p {
                    color: rgba(95, 95, 95, 1);
                }
            }
        }
    }

    .solution-section {
        margin-bottom: 100px;

        ul {
            display: flex;
            gap: 24px;
            flex-wrap: wrap;

            li {
                padding: 12px;
                min-width: 150px;
                flex: 1;
                border-radius: 4px;
                outline: 1px solid $primary;
                outline-offset: -1px;
                min-height: 136px;
                transition: transform 0.3s ease-in-out;

                p {
                    margin-top: 10px;
                }

                &:hover {
                    transform: translateY(-0.75rem) scale(1.1);
                    box-shadow: 0 3px 9px 0 rgba(223, 49, 39, 0.4);
                    outline: none;
                }
            }
        }
    }

    .buyingIns-sec {
        padding: 50px 0px;
        position: relative;
        margin-bottom: 100px;
        z-index: 9;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 80%;
            max-width: 1464px;
            height: 100%;
            content: '';
            background: $primary;
            z-index: -1;
            border-radius: 0 340px 340px 0;
        }

        h2 {
            color: $white;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding-right: 20px;
            counter-reset: li-counter;

            li {
                display: flex;
                align-items: center;
                gap: 24px;
                background: $white;
                box-shadow: -20px 0 28px 0 rgba(0, 0, 0, 0.15);
                padding: 24px;
                border-radius: 12px 0px 0px 12px;
                position: relative;

                &:before {
                    content: counter(li-counter);
                    counter-increment: li-counter;
                    position: absolute;
                    top: calc(50% - 20px);
                    right: -20px;
                    width: 40px;
                    height: 40px;
                    background: $primary;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white;
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 500;
                }

                img {
                    min-width: 100px;
                }

                h4 {
                    margin-bottom: 8px;
                    font-size: 20px;
                }
            }
        }
    }

    .product-slider {
        margin-bottom: 100px;

        .sliderBox {
            min-height: 260px;
            border-radius: 24px;
            display: flex;
            align-items: center;

            &.health-sb {
                background: rgba(255, 243, 244, 1) url('./../../../../public/images/ps-health-bg.png') no-repeat center/cover;
            }

            &.car-sb {
                background: rgba(158, 161, 212, 1) url('./../../../../public/images/ps-car-bg.png') no-repeat center/cover;
            }

            &.term-sb {
                background: rgba(226, 238, 255, 1) url('./../../../../public/images/ps-term-bg.png') no-repeat center/cover;
            }

            &.bike-sb {
                background: rgba(211, 238, 244, 1) url('./../../../../public/images/ps-bike-bg.png') no-repeat center/cover;
            }

            .ins_tag {
                display: inline-flex;
                border: 1px solid $white;
                gap: 12px;
                color: $white;
                border-radius: 8px;
                padding: 8px 12px;
                margin-bottom: 8px;
                font-size: 14px;
                line-height: 21px;
                align-items: center;
                background: $white;
                color: $primary;
            }

            h5 {
                margin-bottom: 24px;

                span {
                    color: $primary;
                    display: inline-block;
                }
            }
        }

        .slick-dots {
            li {
                height: 12px;
                width: 12px;
                margin: 0px 3px;

                button {
                    padding: 0;
                    background-color: rgba(224, 224, 224, 1);
                    border-radius: 50%;
                    height: 12px;
                    width: 12px;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background-color: rgba(79, 79, 79, 1);
                    }
                }
            }
        }
    }

    .partners-section {
        margin-bottom: 100px;

        .partner-box {
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
            text-align: center;
            width: 100%;
            padding: 50px 0;
            transform: skewY(15deg);

            &:nth-of-type(even) {
                transform: skewY(-15deg);

                img {
                    height: 75px;
                    margin: auto;
                    transform: skewY(15deg);
                }
            }

            img {
                height: 75px;
                margin: auto;
                transform: skewY(-15deg);
            }
        }

        .react-multi-carousel-track {
            padding-bottom: 80px;
        }

        .react-multiple-carousel__arrow {
            bottom: 0;
            min-height: 30px;
            height: 40px;
            min-width: 30px;
            width: 40px;
            padding: 0px;
            z-index: 0;
            background: rgba(79, 79, 79, 1);

            &:before {
                color: $white;
                font-size: 12px;
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;

                &:hover {
                    &:before {
                        color: $black;
                    }
                }
            }
        }

        .react-multiple-carousel__arrow--left {
            left: calc(50% - 50px);
        }

        .react-multiple-carousel__arrow--right {
            right: calc(50% - 50px);
        }


        // ff
        .partnerTabs {
            .MuiTabs-flexContainer {
                justify-content: center;
                gap: 60px;

                button {
                    font-size: 16px;
                    font-weight: 500 !important;
                    line-height: 24px;
                    text-transform: capitalize;
                    color: #7A7A7A;
                    padding-top: 10px;
                    padding-bottom: 14px;
                    font-family: 'poppins';
                    min-height: auto;

                    &.Mui-selected {
                        color: $white;
                    }

                    .MuiTouchRipple-root {
                        display: none !important;
                    }
                }
            }
            .MuiTabs-scroller{
                display: inline-flex;
                justify-content: center     ;
            }
            .MuiTabPanel-root {
                padding: 32px 0px 24px;
                padding-top: 32px !important;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;
                    justify-content: center;

                    li {
                        width: calc(25% - 18px);
                        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 12px;
                        min-height: 180px;
                        padding: 24px 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            height: 80px;
                        }
                    }
                }
            }

            .MuiTabs-indicator {
                background: $primary;
            }
        }
    }

    .testimonial-section {
        margin-bottom: 100px;

        .testimonial-box {
            padding: 24px;
            background: $lightRed;
            border-radius: 12px;
            text-align: center;
        }

        .slick-track {
            margin: 70px 0px;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
        }

        .slick-center {
            
            .testimonial-box {
                background: $primary;
                // transform: scaleY(1.4);
                // padding: 0px 12px;

                .innerBox {
                    // transform: scaleY(0.75);

                    h6,
                    p {
                        color: $white;
                    }
                }
            }
        }

        .slick-arrow {
            min-height: 30px;
            height: 40px;
            min-width: 30px;
            width: 40px;
            padding: 0px;
            z-index: 0;
            background: #4f4f4f;
            border-radius: 50%;

            &.slick-prev {
                bottom: 0;
                top: auto;
                left: calc(50% - 50px);
                z-index: 9;

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 6px);
                    left: calc(50% - 6px);
                    opacity: 1;
                    width: 12px;
                    height: 12px;
                    background: url('./../../../../public/images/left-arrow.svg')no-repeat center/6px;
                }
            }

            &.slick-next {
                bottom: 0;
                top: auto;
                right: calc(50% - 50px);

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 6px);
                    left: calc(50% - 6px);
                    opacity: 1;
                    width: 12px;
                    height: 12px;
                    background: url('./../../../../public/images/right-arrow.svg')no-repeat center/6px;
                }
            }
        }
    }

    .satisfied-customer-sec {
        margin-bottom: 100px;

        .sc_box {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            li {
                background: rgba(255, 243, 244, 1);
                border-radius: 4px;
                padding: 24px 20px;
                width: calc(50% - 12px);
                text-align: center;

                h4 {
                    color: rgba(223, 49, 39, 1);
                    margin-bottom: 6px;
                }
            }

        }
    }
}

// form popup checkbox
.popup-Checkbox {
    .checkbox {
        justify-content: center;

        .MuiTypography-root {
            span {
                color: $primary;
            }
        }
    }
}

.self-selectField {
    .MuiToggleButtonGroup-root {
        height: 30px;

        button {
            font-size: 12px;
            line-height: normal;
            text-transform: capitalize;
            font-family: "Poppins", sans-serif !important;
            width: 60px;
            background: rgba(255, 243, 244, 1);
            font-weight: 400;
            color: rgba(79, 79, 79, 1);
            border: none;

            &.Mui-selected {
                background: $primary;
                color: $white;
            }
        }
    }
}