@import "./Mixins.scss";

.responsive-wrapper {
  // padding-top: 90px;

  @media (max-width: 899px) {
    padding-top: 86px;
  }

  h1 {
    @media (max-width: 899px) {
      font-size: 24px;
      line-height: 36px;
      padding: 0 80px;
    }
  }

  h2 {
    @media (max-width: 899px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .mb-margin1 {
    @media (max-width: 899px) {
      margin-bottom: 12px !important;
    }
  }

  .mb-margin2 {
    @media (max-width: 899px) {
      margin-bottom: 24px !important;
    }
  }

  .redbg-header {
    background: $primary;
    position: relative;
    margin-bottom: 60px;
    // min-height: 180px;

    @media (max-width: 899px) {
      margin-bottom: 24px;
    }

    .redbginnerBox {
      padding-top: 62px;
      padding-bottom: 62px;
      position: relative;

      @media (max-width: 899px) {
        padding-top: 22px;
        padding-bottom: 22px;
      }

      &:before {
        @include position(absolute, $bottom: 0, $left: -66px);
        content: "";
        background: url("./../../public/images/wae-vector.svg") no-repeat 0 0/100%;
        height: 110px;
        width: 160px;

        @media (max-width: 899px) {
          @include position(absolute, $bottom: 0, $left: -30px);
          height: 44px;
          width: 70px;
        }
      }
    }

    $othericons: refundCancellation, bikePP, ic3, carPP, aboutus, ic6, career,
      tc, ic9, healthPP, disclaimer, termPP;
    $k: 0;

    @each $icons in $othericons {
      $bacRightP: $k * 120;
      $k: $k + 1;

      .#{$icons} {
        &:after {
          background: url("../../public/images/pages-icons-stripe.svg")
            no-repeat -#{$bacRightP}px
            0px/1440px;
          content: "";
          @include position(absolute, $left: 65px, $top: 10px);
          height: 120px;
          width: 120px;
        }
      }
    }

    @media (max-width: 899px) {
      $othericons: refundCancellation, bikePP, ic3, carPP, aboutus, ic6, career,
        tc, ic9, healthPP, disclaimer, termPP;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 60;
        $k: $k + 1;

        .#{$icons} {
          &:after {
            background: url("../../public/images/pages-icons-stripe.svg")
              no-repeat -#{$bacRightP}px
              0px/720px;
            content: "";
            @include position(absolute, $left: 23px, $top: auto, $bottom: 10px);
            height: 60px;
            width: 60px;
          }
        }
      }
    }

    &:after {
      @include position(absolute, $top: 0, $right: 0);
      content: "";
      background: url("./../../public/images/redbg-header-background.svg")
        no-repeat 0 0/100%;
      width: 219px;
      height: 159px;

      @media (max-width: 899px) {
        width: 90px;
        height: 80px;
      }
    }

    h1 {
      color: $white;
    }
  }

  .about-sec {
    h2 {
      margin-bottom: 16px;

      @media (max-width: 899px) {
        margin-bottom: 12px;
      }
    }

    p {
      margin-bottom: 16px;

      @media (max-width: 899px) {
        margin-bottom: 12px;
        font-size: 14px !important;
        line-height: 21px !important;
      }
    }

    img {
      max-width: 795px;
      width: 100%;
      margin-bottom: 16px;

      @media (max-width: 899px) {
        max-width: 350px;
        margin-bottom: 12px;
      }
    }
  }

  .vision-mission-sec {
    margin-bottom: 60px;

    @media (max-width: 899px) {
      margin-bottom: 24px;
    }

    h2 {
      margin-bottom: 40px;

      @media (max-width: 899px) {
        margin-bottom: 12px;
      }
    }

    .red_tag {
      display: inline-block;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 32px;
      background: $primary;
      color: $white;
      margin-bottom: 24px;

      @media (max-width: 899px) {
        margin-bottom: 12px;
        padding: 10px 24px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .shadowBox {
      padding: 24px;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.1);
      text-align: center;

      @media (max-width: 899px) {
        padding: 12px;
      }

      p {
        margin-bottom: 24px;

        @media (max-width: 899px) {
          font-size: 14px !important;
          line-height: 21px !important;
        }
      }

      .vision-img {
        max-width: 380px;
        width: 100%;

        @media (max-width: 899px) {
          max-width: 250px;
        }
      }

      .mission-img {
        max-width: 539px;
        width: 100%;

        @media (max-width: 899px) {
          max-width: 300px;
        }
      }
    }
  }

  // those page who have text content like tc,pp,disclaimer.gp
  .textContent-pages {
    margin-bottom: 60px;

    @media (max-width: 899px) {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 12px;
      color: $darkGrey;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 899px) {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8px;
        color: rgba(51, 51, 51, 1);
      }
    }

    h5 {
      font-size: 20px;
      line-height: 30px;

      @media (max-width: 899px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    h6 {
      @media (max-width: 899px) {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }
    }

    .content-listpoints {
      padding-left: 40px;

      @media (max-width: 899px) {
        padding-left: 10px;
      }

      li {
        position: relative;
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 21px;
        padding-left: 20px;
        color: $darkGrey;

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: 899px) {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 8px;
          padding-left: 16px;
          color: rgba(51, 51, 51, 1);
        }

        &:before {
          content: "";
          @include position(absolute, $top: 5px, $left: 0);
          background: rgba(189, 189, 189, 1);
          width: 10px;
          height: 10px;
          border-radius: 50%;

          @media (max-width: 899px) {
            @include position(absolute, $top: 4px, $left: 0);
            width: 8px;
            height: 8px;
          }
        }
      }
    }

    .pp_contact_details_box {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin: 24px 0px;

      @media (max-width: 899px) {
        gap: 12px;
        margin: 12px 0px;
      }

      li {
        padding: 12px;
        display: flex;
        align-items: center;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        gap: 12px;
        border-radius: 12px;
        width: calc(25% - 18px);

        @media (max-width: 899px) {
          width: 100%;
        }

        span {
          min-width: 40px;
          height: 40px;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          svg {
            color: $primary;
            font-size: 16px;
          }

          @media (max-width: 899px) {
            min-width: 30px;
            height: 30px;
          }
        }

        p {
          font-weight: 500;
        }

        a {
          font-size: 14px;
          line-height: 21px;
          word-break: break-all;
          color: $black;
          font-weight: 600;

          &:hover {
            color: $primary;
          }

          @media (max-width: 899px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    .gp_shadowBox {
      border-radius: 12px;
      padding: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 12px;

      h6 {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 12px;

        @media (max-width: 899px) {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 6px;
          font-weight: 600;
        }
      }

      p {
        margin-bottom: 0;
        color: $black;
      }
    }
  }

  //end

  // those who have forms
  .form-section {
    margin-bottom: 60px;

    @media (max-width: 899px) {
      margin-bottom: 40px;
    }

    h2 {
      margin-bottom: 24px;
    }

    .contact-img {
      max-width: 650px;
      width: 100%;
    }

    .complaint-img {
      max-width: 570px;
      width: 100%;
    }

    .claim-img {
      width: 100%;
    }

    .career-img {
      max-width: 460px;
      width: 100%;
    }

    .formbox {
      @media (max-width: 899px) {
        text-align: center;
      }
    }
  }

  .JD-box {
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 899px) {
      padding: 12px;
    }

    .job_tag {
      padding: 10px;
      border-radius: 4px;
      background: $lightRed;
      color: $primary;
      display: inline-block;
      margin-bottom: 24px;

      @media (max-width: 899px) {
        margin-bottom: 12px;
      }
    }

    .jd_p {
      margin-bottom: 24px;

      @media (max-width: 899px) {
        margin-bottom: 12px;
      }
    }
  }

  .contactDetails-section {
    margin-bottom: 60px;

    @media (max-width: 899px) {
      margin-bottom: 24px;
    }

    h2 {
      margin-bottom: 60px;

      @media (max-width: 899px) {
        margin-bottom: 24px;
      }
    }

    .cb-img {
      width: 100%;
      max-width: 600px;

      @media (max-width: 599px) {
        max-width: 296px;
      }
    }

    .cd-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;

      li {
        padding: 24px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        width: calc(50% - 12px);
        text-align: center;

        &:first-child {
          min-width: 310px;
        }

        span {
          width: 80px;
          height: 80px;
          background: $lightRed;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;

          svg {
            color: $primary;
            font-size: 28px;
          }
        }

        p {
          font-weight: 500;
        }

        a {
          font-size: 14px;
          line-height: 21px;
          word-break: break-all;
          color: $black;
          font-weight: 500;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .cd-list2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      margin: 50px 0px;

      li {
        padding: 48px 24px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        width: calc((100% / 3) - 16px);
        text-align: center;
        transform: skewY(15deg);

        &:nth-of-type(even) {
          transform: skewY(-15deg);

          .li-inner {
            transform: skewY(15deg);
          }
        }

        .li-inner {
          transform: skewY(-15deg);

          span {
            width: 80px;
            height: 80px;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;

            svg {
              color: $primary;
              font-size: 28px;
            }
          }

          p {
            font-weight: 500;
          }

          a {
            font-size: 14px;
            line-height: 21px;
            word-break: break-all;
            color: $black;
            font-weight: 500;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }

    .mobile_cdlist {
      li {
        padding: 24px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        gap: 12px;
        border-radius: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          min-width: 50px;
          height: 50px;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          svg {
            color: $primary;
            font-size: 28px;
          }
        }

        p {
          font-weight: 500;
        }

        a {
          font-size: 14px;
          line-height: 21px;
          word-break: break-all;
          color: $black;
          font-weight: 500;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  //end

  // productpage csss-------
  .productPages-commoncss {
    h2 {
      letter-spacing: -1px;

      span {
        font-weight: 400;
      }
    }

    h4 {
      span {
        font-weight: 400;
      }
    }

    p {
      margin-bottom: 12px;
      color: $darkGrey;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 899px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
        color: rgba(51, 51, 51, 1);
      }
    }

    .ins-policy-img {
      max-width: 385px;
      width: 100%;

      @media (max-width: 899px) {
        max-width: 310px;
      }
    }

    .left_info_term {
      width: 100%;

      @media (max-width: 899px) {
        max-width: 316px;
      }
    }

    .car-crash-img {
      max-width: 520px;
      width: 100%;

      @media (max-width: 899px) {
        max-width: 338px;
      }
    }

    .bike-crash-img {
      max-width: 420px;
      width: 100%;
    }

    .person-laptop-img {
      width: 100%;

      @media (max-width: 899px) {
        max-width: 386px;
      }
    }

    // common redticklist for all product pages, if u want to do any altertaion do in that page's respective section parent class.
    .redTicklist {
      li {
        position: relative;
        padding-left: 26px;

        &:before {
          @include position(absolute, $top: 3px, $left: 0);
          content: "";
          background: url("./../../public/images/red-tick.svg") no-repeat 0 0 /100%;
          width: 14px;
          height: 15px;
        }

        h6 {
          font-weight: 500;
          margin-bottom: 8px;

          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 4px;
          }
        }

        p {
          color: $black;
          text-align: justify;
        }
      }
    }

    .redlightbox {
      padding: 24px 12px;
      background: $lightRed;
      border-radius: 20px;

      @media (max-width: 899px) {
        padding: 12px 0px;
      }

      h2 {
        @media (max-width: 899px) {
          text-align: center;
        }
      }

      .hi-img {
        max-width: 375px;
        width: 100%;

        @media (max-width: 899px) {
          max-width: 265px;
        }
      }

      .ci-img {
        max-width: 478px;
        width: 100%;
      }

      .bike-img {
        max-width: 328px;
        width: 100%;
      }

      .ti_eligibility-img {
        max-width: 318px;
        width: 100%;
      }

      hr {
        border: none;
        border-bottom: 1px solid rgba(228, 228, 228, 1);
      }

      .heart-img {
        max-width: 230px;
        width: 100%;
      }

      .redTicklist {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        @media (max-width: 599px) {
          gap: 12px;
        }

        li {
          width: calc(50% - 12px);

          @media (max-width: 899px) {
            width: 100%;
          }
        }

        &.term_redlist {
          flex-direction: column;
          gap: 16px;

          li {
            width: 100%;

            h6 {
              font-weight: 600;

              @media (max-width: 599px) {
                font-weight: 500;
                line-height: 21px;
                font-size: 14px;
              }
            }

            p {
              @media (max-width: 899px) {
                line-height: 21px;
                font-size: 14px;
              }
            }
          }
        }

        &.car_redlist {
          flex-direction: column;
          gap: 20px;

          li {
            width: 100%;
          }
        }
      }
    }

    .advantages_buying_hi {
      display: flex;
      flex-wrap: wrap;
      gap: 45px 24px;

      @media (max-width: 899px) {
        margin-top: 24px;
      }

      .list_box {
        position: relative;
        width: calc(50% - 12px);
        padding: 40px 24px 24px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        border-radius: 0 120px 120px 0;

        @media (max-width: 899px) {
          width: 100%;
          padding: 30px 20px 16px 12px;
        }

        span {
          font-size: 16px;
          line-height: 24px;
          padding: 12px 10px;
          display: inline-block;
          background-color: $lightRed;
          border-radius: 0 30px 30px 0;
          color: $primary;
          position: absolute;
          top: -24px;
          left: 0;

          @media (max-width: 899px) {
            font-size: 14px;
            line-height: 21px;
          }
        }

        .content_box {
          display: flex;
          align-items: center;
          gap: 24px;

          @media (max-width: 899px) {
            gap: 12px;
          }

          p {
            text-align: justify;

            @media (max-width: 899px) {
              font-size: 14px;
              line-height: 21px;
            }
          }

          .redTicklist {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            @media (max-width: 899px) {
              gap: 6px 12px;
            }

            li {
              width: calc(50% - 6px);

              @media (max-width: 899px) {
                font-size: 10px;
                line-height: 15px;
              }
            }
          }
        }
      }
    }

    .shadow_box_list {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
      padding: 24px;
      border-radius: 10px;

      @media (max-width: 599px) {
        padding: 12px;
      }

      .redTicklist {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        @media (max-width: 599px) {
          gap: 12px;
        }

        li {
          width: calc(50% - 12px);

          @media (max-width: 899px) {
            width: 100%;
          }
        }
      }
    }

    .term_ins_benefits {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      li {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 24px;
        text-align: center;
        width: calc(25% - 18px);
        min-height: 275px;

        @media (max-width: 899px) {
          width: calc(50% - 12px);
        }

        @media (max-width: 599px) {
          width: 100%;
          min-height: auto;
        }

        img {
          width: 123px;
        }

        h6 {
          margin-top: 8px;
          margin-bottom: 4px;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
        }

        p {
          @media (max-width: 899px) {
            line-height: 21px;
            font-size: 14px;
          }
        }
      }
    }

    .redborder_box {
      padding: 24px;
      border: 1px solid $lightRed;
      border-radius: 12px;

      @media (max-width: 899px) {
        padding: 12px;
      }

      .termPolicyType_list {
        li {
          position: relative;
          padding-left: 16px;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            @include position(absolute, $top: 8px, $left: 0);
            content: "";
            background: $primary;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }

          h6 {
            font-weight: 600;
            margin-bottom: 8px;

            @media (max-width: 599px) {
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 4px;
            }
          }

          p {
            color: $darkGrey;
            text-align: justify;

            @media (max-width: 599px) {
              color: rgba(37, 37, 37, 1);
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }

    .limitations_list {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      @media (max-width: 899px) {
        gap: 12px;
      }

      li {
        border-radius: 20px;
        padding: 20px;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
        text-align: justify;
        color: $darkGrey;
        width: calc(50% - 12px);

        @media (max-width: 899px) {
          color: rgba(37, 37, 37, 1);
          font-size: 14px;
          line-height: 21px;
          padding: 12px;
          width: 100%;
        }

        &:last-child {
          width: 100%;
        }
      }
    }

    .form-box-pp {
      box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 40px 24px 24px;
      position: relative;
      // min-height: 400px;

      @media (max-width: 899px) {
        padding: 24px 12px 12px;
        min-height: auto;
      }

      .backstep {
        @media (max-width: 899px) {
          top: 6px !important;

          svg {
            font-size: 24px !important;
          }
        }
      }

      h6 {
        @media (max-width: 899px) {
          // margin-bottom: 0 !important;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .carInsurance-types {
      .redTicklist {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 24px;

        li {
          color: $darkGrey;
          width: calc(50% - 12px);
        }
      }

      h4 {
        @media (max-width: 899px) {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }

    .comparsion-table {
      width: 100%;
      border-radius: 12px;
      border-collapse: collapse;
      overflow: hidden;

      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
          border-bottom: none;
        }
      }

      th,
      td {
        width: calc(100% / 3);

        @media (max-width: 899px) {
          width: 25%;

          &:first-child {
            width: 50%;
          }
        }

        img {
          width: 30px;
          height: 30px;

          @media (max-width: 899px) {
            width: 22px;
            height: 22px;
          }
        }
      }

      th {
        background: $primary;
        padding: 12px 24px;
        color: $white;
        text-align: center;
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;

        @media (max-width: 899px) {
          padding: 12px;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }

        &:first-child {
          text-align: left;
        }
      }

      td {
        background: $lightRed;
        padding: 24px;
        color: $black;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        @media (max-width: 899px) {
          padding: 12px;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }

        &:first-child {
          text-align: left;
        }

        span {
          border: 1px solid rgba(0, 120, 90, 1);
          padding: 6px 12px;
          color: $darkGrey;
          font-size: 14px;
          line-height: 21px;
          border-radius: 100px;
          font-weight: 400;

          @media (max-width: 899px) {
            padding: 4px 8px;
            font-size: 8px;
            line-height: 12px;
          }
        }
      }
    }

    .comparison-table-bike {
      @extend .comparsion-table;
      border: none;
      min-width: 650px;

      th,
      td {
        width: calc(100% / 4);

        @media (max-width: 899px) {
          width: 25%;
          min-width: 150px;
        }

        img {
          width: 30px;
          height: 30px;

          @media (max-width: 899px) {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    .keyFeature_ci_list {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      @media (max-width: 599px) {
        gap: 16px;
      }

      li {
        width: calc((100% / 3) - 16px);
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 16px;
        border: 1px solid $lightRed;
        border-radius: 12px;

        @media (max-width: 899px) {
          width: calc(50% - 12px);
        }

        @media (max-width: 599px) {
          width: 100%;
        }

        img {
          height: 60px;
          min-width: 100px;
        }

        h6 {
          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 6px !important;
          }
        }
      }
    }

    .buying-online-left-sec {
      @media (max-width: 899px) {
        text-align: center;
        margin-bottom: 24px;
      }
    }

    .orderedlist_policy_points {
      counter-reset: li-counter;

      li {
        position: relative;
        padding-left: 56px;
        margin-bottom: 24px;

        @media (max-width: 599px) {
          margin-bottom: 16px;
        }

        @media (max-width: 599px) {
          padding-left: 42px;
        }

        &:before {
          content: counter(li-counter);
          counter-increment: li-counter;
          position: absolute;
          background: $lightRed;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          left: 0;
          top: 6px;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 21px;
            width: 30px;
            height: 30px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        h6 {
          margin-bottom: 6px;

          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 21px;
          }
        }

        p {
          color: $darkGrey;
          text-align: justify;
        }
      }
    }

    .key-consideration-box {
      min-height: 580px;
      border-radius: 20px;
      background: $lightRed;
      position: relative;
      padding: 50px 32px 0px;
      overflow: hidden;

      &:before {
        content: "";
        @include position(absolute, $bottom: 0, $left: 50%);
        transform: translateX(-50%);
        background: url("./../../public/images/red-arc-stroke.png") no-repeat
          bottom center/100%;
        max-width: 1100px;
        width: 100%;
        height: 100%;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      .kc_car_img {
        max-width: 675px;
        @include position(absolute, $bottom: 13%, $left: 50%);
        transform: translateX(-50%);
      }

      .kc_bike_img {
        max-width: 484px;
        @include position(absolute, $bottom: 8%, $left: 50%);
        transform: translateX(-50%);
      }

      .kc_point_list {
        li {
          width: 120px;
          height: 120px;
          background: $white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 8px 12px;
          position: absolute;

          &:nth-child(1) {
            bottom: 10%;
          }

          &:nth-child(2) {
            bottom: 40%;
            left: 10%;
          }

          &:nth-child(3) {
            top: 18%;
            left: 25%;
          }

          &:nth-child(4) {
            top: 50px;
            left: 45%;
          }

          &:nth-child(5) {
            top: 18%;
            right: 25%;
          }

          &:nth-child(6) {
            bottom: 40%;
            right: 10%;
          }

          &:last-child {
            bottom: 10%;
            right: 32px;
          }

          p {
            font-size: 12px;
            line-height: 18px;
            margin-top: 5px;
            text-align: center;
          }
        }
      }
    }

    .key-consideration-box-mobile {
      border-radius: 20px;
      background: $lightRed;
      padding: 20px 24px 0px;

      .kc_car_img {
        max-width: 354px;
        width: 100%;
      }

      .kc_bike_img {
        max-width: 275px;
        width: 100%;
      }

      .kc_point_list {
        display: flex;
        justify-content: center;
        gap: 24px;
        flex-wrap: wrap;

        li {
          min-width: 120px;
          width: 120px;
          height: 120px;
          background: $white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 8px 12px;

          p {
            font-size: 12px;
            line-height: 18px;
            margin-top: 5px;
            text-align: center;
          }
        }
      }
    }

    .buying-benefits-ci-list {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      @media (max-width: 599px) {
        gap: 16px;
      }

      li {
        width: calc((100% / 3) - 16px);

        @media (max-width: 899px) {
          width: calc(50% - 12px);
        }

        @media (max-width: 599px) {
          width: 100%;
        }

        h6 {
          font-size: 18px;
          line-height: 27px;

          @media (max-width: 599px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 6px !important;
          }
        }
      }
    }

    .claim-process-box {
      border: 1px solid $primary;
      border-radius: 12px;
      padding: 24px 12px;

      @media (max-width: 599px) {
        padding: 12px 0px;
      }

      h6 {
        font-weight: 500;
      }

      .redTicklist {
        li {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 12px;
          text-align: justify;

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            top: 0;
          }
        }
      }

      .claim-process-img {
        max-width: 335px;
        width: 100%;

        @media (max-width: 599px) {
          max-width: 250px;
        }
      }
    }

    .tw-coverage-section {
      .red_tag {
        display: inline-block;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        border-radius: 32px;
        background: $primary;
        color: $white;
        margin-bottom: 24px;

        @media (max-width: 899px) {
          margin-bottom: 12px;
          padding: 10px 24px;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .shadow-box {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 24px;

        @media (max-width: 899px) {
          padding: 12px;
        }

        .redTicklist {
          text-align: left;

          li {
            color: $darkGrey;
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }

            @media (max-width: 899px) {
              margin-bottom: 14px;
            }
          }
        }

        .girlpencil_img {
          max-width: 258px;
          width: 100%;

          @media (max-width: 899px) {
            max-width: 172px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
