@import "./../../SCSS/Mixins.scss";

.popup .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(3px);
}

.fileupload-field {
    label {
        display: flex;
        align-items: center;
        gap: 12px;
        color: $black;
        cursor: pointer;


        svg {
            color: $primary;
        }
    }

    input {
        width: 100%;
    }
}

.MuiTabs-root {
    min-height: auto !important;

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        justify-content: center;
        border: 1px solid $primary;
        display: inline-flex;
        border-radius: 30px;
        overflow: hidden;

        button {
            text-transform: capitalize;
            font-family: 'poppins';
            padding: 8px 24px;
            line-height: 1.5;
            min-height: auto;
            color: $black;

            @media(max-width:699px) {
                padding: 8px 16px;
                font-size: 12px;
                min-width: auto;
            }

            &.Mui-selected {
                background: $primary;
                color: $white;
            }
        }
    }
}

.MuiTabPanel-root {
    padding: 0px !important;
}

.policyCover_features {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    @media(max-width:699px) {
        flex-direction: column;
        gap: 10px;
    }

    li {
        width: calc((100%/3) - 8px);
        color: rgba(37, 37, 37, 1);
        font-size: 12px;
        line-height: 18px;
        padding-left: 22px;
        position: relative;

        @media(max-width:699px) {
            width: 100%;
        }

        &:before {
            @include position(absolute, $top: 0px, $left: 0);
            content: '';
            background: url('./../../../public/images/red-tick.svg') no-repeat 0 0 /100%;
            width: 14px;
            height: 15px;
        }
    }
}

.MuiRating-root {
    font-size: 2rem !important;
    color: rgba(255, 220, 109, 1) !important;

    @media(max-width:699px) {
        font-size: 1.5rem !important;
    }
}