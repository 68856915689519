@import "./../../../../SCSS/Mixins.scss";

.footer {
    .top-sec {
        background: $lightRed;
        padding-top: 60px;
        padding-bottom: 32px;

        ul {
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
                a {
                    color: $darkGrey;

                    &:hover {
                        color: $primary;
                    }
                }

                p {
                    color: $darkGrey;

                    span {
                        font-weight: 700;
                        color: rgba(37, 37, 37, 1);
                    }

                    a {
                        color: rgba(37, 37, 37, 1);
                        font-weight: 700;
                        word-break: break-all;
                    }
                }

                .social-icons {
                    display: flex;
                    gap: 16px;

                    a {
                        display: inline-block;

                        img {
                            width: 22px;
                            height: 22px;
                            transition: transform 1s ease-in-out;

                            &:hover {
                                transform: rotate(720deg) scale(1.5);
                                transition: transform 1s ease-in-out;
                            }
                        }
                    }
                }
            }

        }
    }

    .copyright-bar {
        padding: 10px 12px;
        background: $primary;
        text-align: center;

        p {
            font-weight: 500;
            color: $white;
        }
    }
}