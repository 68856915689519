@import "./SCSS/FontStyle.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import "./Component/InputFields/FieldTypes.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -moz-osx-font-smoothing: grayscale;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-size: 14px;
}
a {
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    opacity: 0.8;
  }
}

img {
  vertical-align: middle;
}

// .MuiModal-root .MuiModal-backdrop {
//   background-color: rgba(0, 0, 0, 0.15);
//   backdrop-filter: blur(3px);
// }

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.row {
  max-width: 1200px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  
  &.rahul {
    display: flex;
    > div {
        width: 50%;
      }
  }
}
.MuiPickersToolbar-content{
  display: none !important;
}