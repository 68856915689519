@import "./../../../../SCSS/Mixins.scss";

.navbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 0px;
    background: $white;
    box-shadow: 5px 5px 20px 0px rgba(34, 34, 34, 0.2);
    z-index: 99;
    .disp {
        display: flex;
        justify-content: space-between;
    }

    ul {
        display: flex;
        justify-content: flex-end;
        gap: 24px;
        align-items: center;

        li {

            a {
                @include fontSize(14px);
                line-height: 21px;
                color: $black;
                font-weight: 400;
                position: relative;
                display: inline-block;

                &::after {
                    content: '';
                    @include position(absolute, $bottom: -5px, $left: 0);
                    height: 2px;
                    @include border-radius(30px);
                    width: 0;
                    background: $primary;
                    transition: width 0.3s ease;
                }

                &:hover {
                    color: $primary;
                    opacity: 1;

                    &::after {
                        width: 100%;
                    }
                }
            }
            .product-link {
                background: transparent;
                border: none;
                padding: 0;
                color: $black;
                font-weight: 400;
                font-size: 14px;
                text-transform: capitalize;
                line-height: 21px;
                display: inline-flex;
                font-family: "Poppins";
                align-items: center;
                position: relative;
                svg {
                    height: 12px;
                    width: 12px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0px;
                    height: 2px;
                    border-radius: 30px;
                    width: 0px;
                    background: $primary;
                    transition: width 0.3s ease;
                }

            }
        }
    }
}