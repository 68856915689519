@import "./../../../../SCSS/Mixins.scss";

.mnavbar {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    min-height: 86px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    padding: 5px 0px;
    z-index: 99;

    .hamburger-menu {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: $primary;
        border-radius: 50%;
    }
}

// sidemenu css
.MuiDrawer-root {
    .MuiPaper-root {
        min-width: 60%;
        max-width: 60%;

        .menuWrapper {
            position: relative;
            min-height: 100vh;

            .top-sec {
                background: $primary;
                padding: 16px 4px;
                margin-bottom: 8px;

                .user_box {
                    padding: 8px;
                    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    background: $white;

                    img {
                        width: 36px;
                        height: 36px;
                    }

                    p {
                        color: $black;
                    }
                }
            }

            .menu-heading {
                padding: 8px 0px;
                font-size: 12px;
                line-height: 18px;
                border-bottom: 1px solid $lightRed;
                color: rgba(255, 243, 244, 1);
            }

            .dash-link {
                padding: 4px 0px;
                display: inline-flex;
                align-items: center;
                gap: 12px;
                width: 100%;

                img {
                    width: 30px;
                    height: 30px;
                }

                span {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: $white;
                }
            }

            .dash-link-login {
                padding: 4px 0px;
                display: inline-flex;
                align-items: center;
                gap: 12px;
                border: 1px solid $white;
                width: 100%;
                padding: 8px;
                border-radius: 100px;

                img {
                    width: 30px;
                    height: 30px;
                    border: 1px solid $white;
                    border-radius: 50%;
                }

                span {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: $white;
                }
            }

            .menu-section {
                padding: 12px 16px;

                .menu-heading {
                    padding-top: 0;
                    color: $primary;
                    border-bottom: 1px solid rgba(217, 25, 32, 0.2);
                }

                ul {
                    li {
                        padding: 10px 0;

                        .nav-link {
                            text-decoration: none;
                            color: #000;
                            @include fontSize(14px);
                            line-height: 21px;
                            font-weight: 400;
                            cursor: pointer;
                            padding: 2px 0px;
                            display: inline-flex;
                            align-items: center;
                            width: 100%;

                        }
                    }
                }
            }
        }
    }
}

.MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.15) !important;
}