@import "./../../../SCSS/Mixins.scss";

.mhomepage-wrapper {
    padding-top: 98px;

    h4 {
        span {
            font-weight: 500;
        }
    }

    .mproduct-section {
        .ip_box {
            padding: 12px;
            // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            text-align: center;
            min-height: 141px;
            width: calc(50% - 12px);

            &.commingSoon {
                position: relative;
                opacity: 0.6;
                cursor: wait;
        
                &:before {
                    background: url("../../../../public/images/coming-soon.svg") no-repeat center center/70%;
                    content: '';
                    position: absolute;
                    top: 12px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }
            }

            &:hover {
                outline: 1px solid $primary;
                outline-offset: -1px;
                animation: clipborder 8s linear infinite;
            }

            img {
                width: 90px;
                height: 90px;
                margin-bottom: 2px;
            }

            p {
                color: rgba(51, 51, 51, 1);
            }

            @keyframes clipborder {

                0%,
                100% {
                    clip: rect(0px, 125.4px, 1px, 0px);
                }

                25% {
                    clip: rect(0px, 1px, 125.4px, 0px);
                }

                50% {
                    clip: rect(124.4px, 125.4px, 125.4px, 0px);
                }

                75% {
                    clip: rect(0px, 125.4px, 125.4px, 124.4px);
                }
            }
        }

        .sliderBox {
            height: 196px;
            border-radius: 24px;
            display: flex;
            gap: 16px;
            align-items: center;
            padding: 0px 12px;
            overflow: hidden;

            &.health-sb {
                background: rgba(255, 130, 134, 1) url('./../../../../public/images/banner-bg.svg') no-repeat center/100%;
            }

            &.car-sb {
                background: rgba(158, 161, 212, 1) url('./../../../../public/images/banner-bg.svg') no-repeat center/100%;
            }

            &.term-sb {
                background: rgba(251, 187, 112, 1) url('./../../../../public/images/banner-bg.svg') no-repeat center/100%;
            }

            span {
                display: inline-flex;
                border: 1px solid $white;
                gap: 12px;
                color: $white;
                border-radius: 8px;
                padding: 7px 12px;
                margin-bottom: 16px;
                align-items: center;

                img {
                    height: 24px;
                }
            }

            h4 {
                color: $white;
                margin-bottom: 24px;
                @include fontSize(14px);
                line-height: 20px;
            }

            p {
                color: $white;
                margin-bottom: 24px;
                @include fontSize(12px);
            }
            .ab-img{
                @media(max-width:499px){
                    height: 100%;
                }
            }
        }

        .slick-dots {
            li {
                height: 12px;
                width: 12px;
                margin: 0px 3px;

                button {
                    padding: 0;
                    background-color: rgba(224, 224, 224, 1);
                    border-radius: 50%;
                    height: 12px;
                    width: 12px;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background-color: rgba(79, 79, 79, 1);
                    }
                }
            }
        }
    }

    .mredbg-section {
        .redbox {
            background: $lightRed;
            padding: 40px 24px;
            border-radius: 24px;
            text-align: center;
            position: relative;
            overflow: hidden;

            &:before {
                content: "";
                position: absolute;
                top: -20px;
                left: -20px;
                background: url('./../../../../public/images/curve-bottom.svg')no-repeat 0 0/100%;
                width: 333px;
                height: 179px;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -20px;
                right: -20px;
                background: url('./../../../../public/images/curve-top.svg')no-repeat 0 0/100%;
                width: 333px;
                height: 179px;
            }

            img {
                max-width: 220px;
            }

            h5 {
                line-height: 27px;

                span {
                    font-weight: 500;
                }
            }
        }
    }

    .mabout-section {
        .aboutbg_box {
            text-align: center;

            img {
                max-width: 358px;
                width: 100%;
            }

            h4 {
                span {
                    font-weight: 500;
                }
            }
        }
    }

    .mwhy-choose {
        .wc_sec_img {
            max-width: 380px;
            width: 100%;
        }

        .wc_points {
            li {
                box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                padding: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    width: 90px;
                    height: 90px;
                }

                h6 {
                    margin-bottom: 12px;
                }

                p {
                    color: rgba(95, 95, 95, 1);
                }
            }
        }
    }

    .mbuyingIns-sec_outer {
        .mbuyingIns-sec {
            padding: 24px 0px;
            position: relative;
            z-index: 9;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                width: 75%;
                height: 100%;
                content: '';
                background: $primary;
                z-index: -1;
                border-radius: 0 340px 340px 0;
            }

            h2 {
                color: $white;
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding-right: 15px;
                padding-left: 20px;
                counter-reset: li-counter;

                li {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    background: $white;
                    box-shadow: -20px 0 28px 0 rgba(0, 0, 0, 0.15);
                    padding: 12px 20px 12px 12px;
                    border-radius: 12px 0px 0px 12px;
                    position: relative;

                    &:before {
                        content: counter(li-counter);
                        counter-increment: li-counter;
                        position: absolute;
                        top: calc(50% - 15px);
                        right: -15px;
                        width: 30px;
                        height: 30px;
                        background: $primary;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $white;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                    }

                    img {
                        min-width: 60px;
                    }

                    h6 {
                        margin-bottom: 6px;
                        font-size: 16px;
                        line-height: normal;
                    }
                }
            }
        }
    }

    .mproduct-slider {
        .sliderBox {
            min-height: 196px;
            border-radius: 24px;
            display: flex;
            align-items: center;

            &.health-sb {
                background: url('./../../../../public/images/ps-health-bg.png') no-repeat center/cover;
            }

            &.car-sb {
                background: url('./../../../../public/images/ps-car-bg.png') no-repeat center/cover;
            }

            &.term-sb {
                background: url('./../../../../public/images/ps-term-bg.png') no-repeat center/cover;
            }

            &.bike-sb {
                background: url('./../../../../public/images/ps-bike-bg.png') no-repeat center/cover;
            }

            .ins_tag {
                display: inline-flex;
                border: 1px solid $white;
                gap: 12px;
                color: $white;
                border-radius: 8px;
                padding: 4px 8px;
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 18px;
                align-items: center;
                background: $white;
                color: $primary;
            }

            p {
                margin-bottom: 24px;
                font-weight: 600;

                span {
                    color: $primary;
                    display: inline-block;
                }
            }
        }

        .slick-dots {
            li {
                height: 12px;
                width: 12px;
                margin: 0px 3px;

                button {
                    padding: 0;
                    background-color: rgba(224, 224, 224, 1);
                    border-radius: 50%;
                    height: 12px;
                    width: 12px;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background-color: rgba(79, 79, 79, 1);
                    }
                }
            }
        }

        .slick-arrow {
            min-height: 30px;
            height: 30px;
            min-width: 30px;
            width: 30px;
            padding: 0px;
            z-index: 0;
            background: rgba(79, 79, 79, 1);
            border-radius: 50%;
            top: auto;
            bottom: -40px;
            transform: none;

            &.slick-prev {
                top: auto;
                left: calc(50% - 35px);
                z-index: 9;

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 7px);
                    left: calc(50% - 5px);
                    width: 12px;
                    height: 12px;
                    background: url('./../../../../public/images/left-arrow.svg')no-repeat center/6px;
                }
            }

            &.slick-next {
                top: auto;
                right: calc(50% - 35px);

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 7px);
                    left: calc(50% - 5px);
                    width: 12px;
                    height: 12px;
                    background: url('./../../../../public/images/right-arrow.svg')no-repeat center/6px;
                }
            }
        }
    }

    .msolution-section {
        ul {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            justify-content: center;

            li {
                padding: 12px;
                width: calc(50% - 6px);
                border-radius: 4px;
                outline: 1px solid $primary;
                outline-offset: -1px;
                min-height: 136px;
                transition: transform 0.3s ease-in-out;

                p {
                    margin-top: 10px;
                }
            }
        }
    }

    .mtestimonial-section {
        .testimonial-box {
            padding: 12px;
            background: $primary;
            border-radius: 12px;
            text-align: center;

            p {
                color: $white;
                font-weight: 400;
            }
        }

        .slick-arrow {
            min-height: 30px;
            height: 30px;
            min-width: 30px;
            width: 30px;
            padding: 0px;
            z-index: 0;
            background: rgba(79, 79, 79, 1);
            border-radius: 50%;
            top: auto;
            bottom: -50px;
            transform: none;

            &.slick-prev {
                top: auto;
                left: calc(50% - 35px);
                z-index: 9;

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 7px);
                    left: calc(50% - 5px);
                    width: 12px;
                    height: 12px;
                    background: url('./../../../../public/images/left-arrow.svg')no-repeat center/6px;
                }
            }

            &.slick-next {
                top: auto;
                right: calc(50% - 35px);

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 7px);
                    left: calc(50% - 5px);
                    width: 12px;
                    height: 12px;
                    background: url('./../../../../public/images/right-arrow.svg')no-repeat center/6px;
                }
            }
        }
    }

    .msatisfied-customer-sec {
        .sc_box {
            li {
                background: rgba(255, 243, 244, 1);
                border-radius: 4px;
                padding: 12px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                gap: 12px;

                img {
                    height: 88px;
                }

                h6 {
                    color: rgba(223, 49, 39, 1);
                    margin-bottom: 6px;
                }
            }

        }
    }

    .mpartners-section {
        .partnerTabs {
            .MuiTabs-flexContainer {
                justify-content: center;

                button {
                    font-size: 14px;
                    font-weight: 500 !important;
                    line-height: 21px;
                    text-transform: capitalize;
                    color: #7A7A7A;
                    padding: 8px 16px;
                    font-family: 'poppins';
                    min-width: auto;

                    &.Mui-selected {
                        color: $white;
                    }

                    .MuiTouchRipple-root {
                        display: none !important;
                    }
                }
            }

            .MuiTabs-scroller {
                display: inline-flex;
                justify-content: center;
            }

            .MuiTabPanel-root {
                padding: 12px 0px;
                padding-top: 24px !important;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    justify-content: center;

                    li {
                        width: calc((100% / 3) - 8px);
                        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 12px;
                        padding: 12px 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            height: 50px;
                        }
                    }
                }
            }

            .MuiTabs-indicator {
                background: $primary;
            }
        }
    }
}

// form popup checkbox
.popup-Checkbox {
    .checkbox {
        justify-content: center;

        .MuiTypography-root {
            font-size: 12px;
            color: $black;

            span {
                color: $primary;
            }
        }
    }
}